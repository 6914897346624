
import {FcDoughnutChart, FcDataSheet, FcBarChart, FcAreaChart} from "react-icons/fc";


export const menuItems = [
    {address: '/', title: 'Home', icon: FcDataSheet, img: require('../images/menu-1.png')},
    {address: '/invite-friends', title: 'Invite', icon: FcBarChart, img: require('../images/menu-2.png')},
    {address: '/my-team', title: 'Team', icon: FcDoughnutChart, img: require('../images/menu-3.png')},
    {address: '/personal-information', title: 'Account', icon: FcDoughnutChart, img: require('../images/menu-4.png')},
    {address: '/customer-care', title: 'Support', icon: FcDoughnutChart, img: require('../images/menu-5.png')},
]

