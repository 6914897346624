import React, {useState, useEffect} from "react";
import {Images} from "./index";


function NoData({type, title}) {

    return <div className="text-center" style={{marginTop: 50}}>
            
            <Images height={150} src={require('../../images/info.png')} />
            
            <div style={{marginTop: 20}}>
                <h5 className="text-dark text-center">{title ?? `You currently have no ${type ?? 'data'}.`}</h5>
            </div>

        </div>

}

export default NoData;