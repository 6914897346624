import React, {useState, useEffect} from "react";
import {WebLink, SiteWide, Images} from "../customComponents";
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";

import {mainStyle, mainColor, header, footer, otherColors, systemColor} from '../../utility/styles';
import { useLocation } from 'react-router-dom';
import NoData from './NoData';



function TransactionHolder(props) {

    const { pathname } = useLocation();
    const systemColorManager = systemColor()


    return (
        <div class=""> 

            {/*<div className="flexify" style={{marginBottom: 20}}>

                <WebLink style={{width: '50%'}} to={'/deposit-records'}><button className="btn btn-block" style={{borderTopRightRadius: '0px', borderBottomRightRadius: 0, ...systemColorManager?.[pathname == '/deposit-records'?'btn_2':'bg_1'], ...systemColorManager.txt_1}}>RECHARGES</button></WebLink>
            
                <WebLink style={{width: '50%'}} style={{}} to={'/withdrawal-records'}><button className="btn btn-block" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, ...systemColorManager?.[pathname == '/withdrawal-records'?'btn_2':'bg_1'], ...systemColorManager.txt_1}}>WITHDRAWALS</button></WebLink>

            </div>*/}

            {(props?.data?.length ?? 0) > 0 ? props.children: <NoData />}

        </div>
    );

}

export default TransactionHolder;