import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";
import parse from 'html-react-parser';
import {systemColor} from '../utility/styles';
import FullScreenInputHolder from "./customComponents/FullScreenInputHolder";

    

function About(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    const systemColorManager = systemColor()

    return (
        <SiteWide id="main" title={"About"}>
            <FullScreenInputHolder>
                <div>

                    <div style={{...systemColorManager.txt_1}}>
                        {parse(site_settings?.content_settings?.[0]?.con1 ?? '')}
                    </div>

                </div>
            </FullScreenInputHolder>
        </SiteWide>
    );

}

export default About;