import * as actionTypes from "../actions/actionTypes";

const initialState = {
  trades: null,
};

export default (state = initialState, action = {}) => {

  switch (action.type) {
    
    case actionTypes.TRADE_SAVE_PRODUCTS:
      return {
        ...state,
        trades: {
          ...state.trades,
          products: action.data,
        }
        
      };
    
    case actionTypes.TRADE_SAVE_USER_GAMES:
      return {
        ...state,
        trades: {
          ...state.trades,
          games: action.data,
        }
        
      };

    default:
      return state;
  }
};
