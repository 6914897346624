import React, {Component, useState, useEffect} from "react";

import Navbar from "../../layouts/Navbar";
import {WebLink, SiteWide} from "../../customComponents";
import NoData from "../../customComponents/NoData";

import Section from "../../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../../customComponents";
import Colors from '../../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../../utility/Helper1';

import {tradeActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, allTradeSelect, homeScreenStatSelect } from '../../../redux/selectors/index';
import CustomAgo from "../../customComponents/CustomAgo";

import {systemColor} from '../../../utility/styles';
import { AiFillClockCircle, AiOutlineDownSquare } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";

import PaginatedItems from "../../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";

import DownModal from "../../layouts/DownModal";

import parse from 'html-react-parser';
import Countdown from 'react-countdown';
import BarLoader from 'react-spinners/BarLoader'








function MyGameList() {

    const params = useParams();
    const block = params.block
    const arrangeBlock = (block == 1)?8:(block == 3)?9:10


    const userData                          = useSelector(userSelect);
    const allTrades                          = useSelector(allTradeSelect)

    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [loadingText, setLoadingText]     = useState('Loading Bets');

    const [gettingProducts, setGettingProducts]             = useState(true);
    const [loading, setloading]                             = useState(true);

    const [amount, setAmount]                             = useState('');
    const [selectedBoxId, setSelectedBoxId]               = useState(null);

    

    const [getCurrentPage, setGetCurrentPage]             = useState(1);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    const [openBlockData, setOpenBlockData]             = useState(false);
    const [theGames, setTheGames]                       = useState([]);
    const [refreshingGameData, setRefreshingGameData]                       = useState(false);

    const [playableGameAndNext, setPlayableGameAndNext]                       = useState([]);


    


    const history = useHistory();
    const systemColorManager = systemColor()


   const dispatch = useDispatch();



    const gameBoxes = [
            {id: 1, title: 'Big',           earn: 1.9},
            {id: 2, title: 'Small',         earn: 1.9},
            {id: 3, title: 'Odd',           earn: 1.9},
            {id: 4, title: 'Even',          earn: 1.9},
            {id: 5, title: 'Big-Odd',       earn: 3.9},
            {id: 6, title: 'Big-Even',      earn: 3.9},
            {id: 7, title: 'Small-Odd',     earn: 3.9},
            {id: 8, title: 'Small-Even',    earn: 3.9},
        ]




    useEffect(() => {
        setloading(true)
        loadGamesForBlock()
    }, [])



    const loadGamesForBlock = (callback = () => {}) => {
        dispatch(tradeActions.onGetTradesProducts(`trade/block/games/mine`, response => {
            setloading(false)
            setTheGames(response.data)
            callback()

        }))
    }





    function Template({ currentItems }){

        return (
            <table style={{maxWidth: '100%', borderCollapse: 'collapse', backgroundImage: `linear-gradient(45deg, #4876ec, #402662)`}} className="table able-responsive">
                <thead>
                    <tr>
                      <th style={{ border: 'none', textAlign: 'left'}}>Bet</th>
                      <th style={{ border: 'none', textAlign: 'left'}}>Id</th>
                      <th style={{ border: 'none', textAlign: 'center' }}>Result</th>
                        <th style={{ border: 'none', textAlign: 'center' }}>Result</th>
                        <th style={{ border: 'none', textAlign: 'center' }}>Reward</th>
                      {/*<th style={{ border: 'none', textAlign: 'right' }}>Showcase</th>*/}
                    </tr>
                </thead>
                <tbody>

                {currentItems && currentItems?.map((item, index) => {

                    const timeString = new Date(item?.timeframe)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });


                    const firstTwoChars = item?.hash?.slice(0, 2) ?? '';
                    const lastFourChars = item?.hash?.slice(-4) ?? '';
                    const lastHash = `${firstTwoChars}...${lastFourChars}`


                    // const result = item.resultBoxId
                    const result = item.resultbox
                    let namedResult = "";

                     
                    let useles = result?.split(',').forEach((eachNumber) => {
                        namedResult += `<div style="display: 'inline-block'; padding: 2; background-color: ${site_settings?.boxes_settings?.[eachNumber - 1]?.['boxcolor']}">${site_settings?.boxes_settings?.[eachNumber - 1]?.['boxname']}</div> `
                    })

                    return <tr key={index}>
                        <td className="g-danger" style={{border: 'none', textAlign: 'left'}}>${item.capital}</td>
                        <td className="g-danger" style={{border: 'none', textAlign: 'left'}}>{item.txn_id}</td>
                        
                        <td style={{ border: 'none', textAlign: 'center' }}>{lastHash}</td>
                        <td className="g-info" style={{ border: 'none', textAlign: 'center' }}>{parse(namedResult)}</td>
                        <td className="g-info" style={{ border: 'none', textAlign: 'center' }}>{item.walkAwayAmount?`$${item.walkAwayAmount}`:''}</td>




                        {/*<td style={{ border: 'none', textAlign: 'right' }}>lumn1</td>*/}
                      </tr>
                })}

                </tbody>
            </table>)

        
    }

    const theGamesFromLast = theGames;

    return (

        <SiteWide removeFooter={true} textColor={{color: '#fff'}} background={{background: "#3c0f8b"}} loading={loading} overlayText={loadingText} title={`My Games`}>
            

            <Images style={{borderRadius: 10, marginBottom: 10}} source={site_settings.image_settings?.[0]?.[`image8`]} alt="my games" />

        

            {(theGamesFromLast?.length ?? 0) > 0 && <PaginatedItems currentPageCallBack={() => {}} Template={Template} arrayOfDataToDisplay={theGamesFromLast} itemsPerPage={50} />}




        </SiteWide>
    );
}

export default MyGameList;


