import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../../utility/styles';
import CustomAgo from "../customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "../customComponents";

import {RiUser4Line } from "react-icons/ri";
import PaginatedItems from "../customComponents/pagination/PaginatedItems";
import {systemColor} from '../../utility/styles';
import './team.css';
import FullScreenInputHolder from "../customComponents/FullScreenInputHolder";



function Referrals() {    

    const systemColorManager = systemColor()


    const [loadingText, setLoadingText]   = useState('Loading team...');
    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [activeClass, setActiveClass]    = useState(1)

    const [mainUplineData, setMainUplineData]    = useState({})

    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [viewingLevel, setViewingLevel]     = useState(1)
    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])

    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);


    const dispatch = useDispatch();

    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            
console.log('response', response)
            setLoading(false)

            setRefDataRaw(response?.data)
            setRefData(response?.data?.referrals)
            setMainUplineData(response?.data?.upline)

        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])


    const showReferral = (site_settings?.operational_settings?.useReferralBonus ?? 0) == 1
    const showRebate = (site_settings?.operational_settings?.useRebateBonus ?? 0) == 1

    const userData = useSelector(userSelect);


    const affiliatePlace = ({ currentItems }) => {
        return <div className="col-12">
            <table className="text-center" style={{backgroundColor: '#4876ec', border: 'none'}} className={`table triped ordered hover`}>
              <thead style={{border: 'none', backgroundColor: 'transparent'}}>
                <tr>
                  <th className="text-left" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}>Username</span></th>
                  {showReferral && <th className="text-left" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}>Referral</span></th>}
                  {showRebate && <th className="text-left" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}>Rebate</span></th>}
                  {1>2&&<th className="text-right" style={{border: 'none', height: 20}}><span style={{...systemColorManager.txt_1}}>Signup date</span></th>}
                </tr>
              </thead>
              <tbody style={{border: 'none'}}  className="text-white">

                {currentItems?.map((eachdownline, index) => {

                    let refWithdrawnFromMe = eachdownline?.['refWithdrawnFromMe_' + viewingLevel];
                    let rebWithdrawnFromMe = eachdownline?.['rebateWithdrawnFromMe_' + viewingLevel];

                return <tr style={{border: 'none'}}>
                    <td className="text-left" style={{border: 'none', ...systemColorManager.txt_1}}><span>{eachdownline?.username}</span></td>
                    {showReferral && <td className="text-left" style={{border: 'none', ...systemColorManager.txt_1}}><span>{refWithdrawnFromMe?money(refWithdrawnFromMe):'-'}</span></td>}
                    {showRebate && <td className="text-left" style={{border: 'none', ...systemColorManager.txt_1}}><span>{rebWithdrawnFromMe?money(rebWithdrawnFromMe):'-'}</span></td>}
                    {1>2&&<td className="text-right" style={{border: 'none', ...systemColorManager.txt_1}}><span>{niceDate(eachdownline?.date)}</span></td>}
                </tr>
                })}
                

              </tbody>
            </table>
        </div>
    }


    const changeTab = (newTab) => {
        setActiveClass(newTab)
    }


    const totalPlaces = [
                            {title: 'Logins',               count: refDataRaw?.['loginCount'] ?? 0}, 
                            {title: 'Bets Capital',   count: refDataRaw?.['betEarningAmount'] ?? 0}, 
                            {title: 'Bets Earning',           count: refDataRaw?.['betAmount'] ?? 0}, 
                            {title: 'Total Deposits',       count: refDataRaw?.['totalDeposits'] ?? 0},
                            {title: 'Total Withdrawals',    count: refDataRaw?.['totalWithdrawals'] ?? 0},
                        ]

    return (<SiteWide loading={loading} overlayText={loadingText} title="Team">
        
                


                                <div className="" style={{ overflowX: "scroll", marginTop: 20}}>
                                    <div style={{ display: "flex" }}>
                                        {site_settings?.referral_settings?.map((item, i) => {
                                            
                                            let thisCount = i+1

                                            let isActiveHere = viewingLevel == thisCount?systemColorManager.btn_1:systemColorManager.btn_2

                                            let backg = systemColorManager.border_2
                                            let countLev = refData?.['level' + thisCount]?.length ?? 0

                                            return <div key={i} className="flexify" style={{cursor: 'pointer', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 5, minWidth: 80, height: 80, 
                                                ...backg, marginRight: 5, ...isActiveHere, ...systemColorManager.borders_color, ...systemColorManager.txt_1}} 
                                                 onClick={() => setViewingLevel(thisCount)}>
                                                
                                                <span className="h3 prem">{countLev}</span>
                                                <span className="">Level {thisCount}</span>

                                            </div>

                                        })}
                                    </div>
                                </div>
                                   




{console.log('refData', refData)}


                                <div className="" style={{marginTop: 60, padding: 0}}>
                                    <div className="row">
                                                                
                                        {(refData?.['level'+viewingLevel]?.length ?? []) > 0 && <PaginatedItems Template={affiliatePlace} arrayOfDataToDisplay={refData['level'+viewingLevel]} itemsPerPage={20} />}

                                    </div>
                                </div>







     
                    

        </SiteWide>
    );

}

export default Referrals;