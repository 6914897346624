import React, {useState, useEffect} from "react";
import {WebLink, SiteWide, Images} from "../customComponents";
import {mainStyle, mainColor, header, footer, otherColors, systemColor} from '../../utility/styles';
import { useLocation } from 'react-router-dom';



function FullScreenInputHolder(props) {

    const systemColorManager = systemColor()


    return (
        <div className="row" style={{ ...systemColorManager.bg_2, minHeight: '100vh'}}>
            <div className="col-1 col-md-1"></div>
            <div className="col-10 col-md-10">
                {props.children}
            </div>
        </div>

    );

}

export default FullScreenInputHolder;