import React, {Component, useState, useEffect} from "react";

import Navbar from "../../layouts/Navbar";
import {WebLink, SiteWide} from "../../customComponents";
import NoData from "../../customComponents/NoData";

import Section from "../../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../../customComponents";
import Colors from '../../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../../utility/Helper1';

import {tradeActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, allTradeSelect, homeScreenStatSelect } from '../../../redux/selectors/index';
import CustomAgo from "../../customComponents/CustomAgo";

import {systemColor} from '../../../utility/styles';
import { AiOutlineClockCircle } from "react-icons/ai";

import PaginatedItems from "../../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";

import DownModal from "../../layouts/DownModal";

import "./styles/responsive.css";
import "./styles/style.css";
import parse from 'html-react-parser';
import Countdown from 'react-countdown';




const nextMatch = {
    slidesToShow: 2.5,
    slidesToScroll: 2,
    dots: false,
    infinite: false,
}



function AllFixtures() {

    const userData                          = useSelector(userSelect);
    const allTrades                          = useSelector(allTradeSelect)

    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Getting user purchases');

    const [gettingProducts, setGettingProducts]             = useState(true);
    const [loading, setloading]                          = useState(true);

    const [getCurrentPage, setGetCurrentPage]             = useState(1);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    
    const [homeFixture, setHomeFixture]   = useState(allTrades?.games ?? []);


    const history = useHistory();
    const systemColorManager = systemColor()


   const dispatch = useDispatch();


   useEffect(() => {

        dispatch(tradeActions.onGetUserTrades("trade/games/load", response => {

         setGettingProducts(false)
         setloading(false)

        }))

    }, [])




   const updateStore = () => {
    setHomeFixture(allTrades?.games ?? []);
   }

   useEffect(() => {

       updateStore()

    }, [allTrades.games])


   const sellBack = (item) => {

        setLoadingText(`Selling ${item.tradeImageName}`)
        setloading(true);

        dispatch(tradeActions.onPOSTTheTrades({txn_id: item.txn_id}, "trade/sellback", response => {
console.log('response', response)
            setloading(false);
            notify(response.msg, response.code);
            updateStore()

            
            
        }))
   }

    function Template({ currentItems }){

        return (
            <>
                {currentItems && currentItems.map((item) => {


                return <div class="col-6">
                  <div class="car mb-4 shadow-sm" style={{backgroundColor: '#fff'}}>
                    

                    <div className="text-center" style={{paddingTop: 10, height: 60}}>
                        <Images class="card-img-top" source={item.tradeImageFile} style={{height: 'auto', maxWidth: '40px'}} alt={item.tradeImageName} />
                    </div>

                    <div class="card-body">
                      <h5 class="card-title ellipsis text-center" style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "200px",}}>{item.tradeImageName}</h5>
                      <p class="card-text text-center"><h4 class="text-muted prem">${item.capital}</h4></p>
                      
                      <div class="d-ems-center">
                        
                        <div class="btn-grop text-center">

                            {item.active == 1 && <Countdown
                                date={new Date(item?.timeToMatured)}
                                renderer={({ hours, minutes, seconds, completed }) => {
                                  if (completed || item.withdrawalHastened == 1) {
                                    return <button onClick={() => window.confirm(`This product will be sold back for ${item.profit}. Click OK to continue`) && sellBack(item)} type="button" class="btn btn-block btn-sm btn-outline-secondary">Sell</button>
                                  } else {
                                    return <span className="text-dark">Sellable after {hours}:{minutes}:{seconds}</span>;
                                  }
                                }}
                            />}


                            {item.active == 0 && <span onClick={() => window.confirm(`This product was sold back for ${item.profit}`)} type="button" class="text-dark bblock tn-sm btn-outine-secondary">Sold (${item.profit})</span>}
                            
                        </div>
                        
                      </div>
                    </div>
                  </div>}

                </div>})}


            </>)
        
    }


    const getCurrenPageTaawa = (thePage) => {
      setGetCurrentPage(thePage)
    }



    return (

        <SiteWide loading={loading} overlayText={loadingText} title={`My Products`}>
            

            

            <div className="">
               
               <div className="row">
                 <div className="col-sm-12">
                    <h1 className="product_taital">{parse(site_settings?.content_settings?.[0]?.con6 ?? '')}</h1>
                    <p className="product_text">{parse(site_settings?.content_settings?.[0]?.con7 ?? '')}</p>
                 </div>
               </div>

               {!gettingProducts && <div className="row">
                  
                  {(homeFixture?.length ?? 0) > 0 && <PaginatedItems currentPageCallBack={getCurrenPageTaawa} Template={Template} arrayOfDataToDisplay={homeFixture} itemsPerPage={10} />}

               </div>}




            </div>



        </SiteWide>
    );
}

export default AllFixtures;