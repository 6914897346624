import React, {Component, useEffect} from "react";
import Section from "./layouts/Section";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {WebLink, SiteWide} from "./customComponents";
import Faqo from "react-faq-component";
import {systemColor} from '../utility/styles';
import FullScreenInputHolder from "./customComponents/FullScreenInputHolder";

function Faq(){

    const bakground = require('../images/space.jpg');

    const site_settings = useSelector(allRemoteSettingsSelect);
    
    const systemColorManager = systemColor()

    const styles = {
        bgColor: 'transparent',
        titleTextColor:     systemColorManager.txt_singly_1,
        rowTitleColor:      systemColorManager.txt_singly_1,
        rowContentColor:    systemColorManager.txt_singly_1,
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: false,
        //expandIcon: "+",
        //collapseIcon: "-",
    };

    const data = {    
        title: "Frequently Asked Questions",
        rows: site_settings?.faq?.map(function(item, index){ return {title: item.question, content: item.answer} })

    };

    return (
        <SiteWide title={`Frequently Asked Questions`}>

            <FullScreenInputHolder>
                <div>
                    <Faqo
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
            </FullScreenInputHolder>

        </SiteWide>
    );

}

export default Faq;