import React, {Component, useState, useEffect} from "react";

import Navbar from "../../layouts/Navbar";
import {WebLink, SiteWide} from "../../customComponents";
import NoData from "../../customComponents/NoData";

import Section from "../../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../../customComponents";
import Colors from '../../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../../utility/Helper1';

import {tradeActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, allTradeSelect, homeScreenStatSelect } from '../../../redux/selectors/index';
import CustomAgo from "../../customComponents/CustomAgo";

import {systemColor} from '../../../utility/styles';
import { AiFillClockCircle, AiOutlineDownSquare } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";

import PaginatedItems from "../../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";

import DownModal from "../../layouts/DownModal";

import parse from 'html-react-parser';
import Countdown from 'react-countdown';
import BarLoader from 'react-spinners/BarLoader'



function boxers(thisObject, boxSettingsArray) {
  const winningHash = thisObject['hash'];
  if (winningHash === 'XXXXXXXXXX') { return ''; }

  const lastCharacter = winningHash.slice(-1);

  let resultBoxId = "";

  for (let i = 1; i <= boxSettingsArray.length; i++) {
    if (i > 4) { continue; }

    const checkRepost = boxSettingsArray[i-1]['boxsequence'];
    const checkRepostArr = checkRepost.split('');

    if (checkRepostArr.includes(lastCharacter)) {
      resultBoxId += `${i},`;
    }
  }

  resultBoxId = resultBoxId.slice(0, -1); // trim last character which is comma
    // for profit

    let profit, oddSettled, win;

    if(thisObject?.['boxId']){
      const boxNumberPlayed = thisObject['boxId'] - 1;
      const playedGameSequence = boxSettingsArray[boxNumberPlayed]['boxsequence'];
      const playedGameSequenceArray = playedGameSequence.split('');

      if (playedGameSequenceArray.includes(lastCharacter)) { //wins
        oddSettled = boxSettingsArray[boxNumberPlayed]['boxpercent'];
        win = true;
      } else {
        oddSettled = boxSettingsArray[boxNumberPlayed]['boxloosingodd'];

        win = false;
      }

        profit = thisObject['capital'] * oddSettled;
    }

  return { boxshow: resultBoxId, win: win, profit: profit };
}





function BlockGames() {

    const params = useParams();
    const block = params.block
    const arrangeBlock = (block == 1)?8:(block == 3)?9:10
    const imageThings = (block == 1)?5:(block == 3)?6:7
    const approvedTimebreaker = block - 1


    const userData                          = useSelector(userSelect);
    const allTrades                          = useSelector(allTradeSelect)

    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [loadingText, setLoadingText]     = useState('Loading Bets');

    const [gettingProducts, setGettingProducts]             = useState(true);
    const [loading, setloading]                             = useState(true);

    const [amount, setAmount]                             = useState('');
    const [selectedBoxId, setSelectedBoxId]               = useState(null);

    

    const [getCurrentPage, setGetCurrentPage]             = useState(1);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    const [openBlockData, setOpenBlockData]             = useState(false);
    const [theGames, setTheGames]                       = useState([]);

    const [myPlayedGames, setMyPlayedGames]                       = useState([]);
    const [openMyPlayedGame, setOpenMyPlayedGame]                       = useState(false);


    const [refreshingGameData, setRefreshingGameData]                       = useState(false);

    const [playableGameAndNext, setPlayableGameAndNext]                       = useState([]);
    const [timeIsZero, setTimeIsZero]                       = useState(false);
    const [minute, setMinute]                       = useState(new Date().getMinutes());
    const [dataChanged, setDataChanged]                       = useState(1);
    const [timeBreaker, setTimeBreaker]                       = useState(0);
    const [openTradingTable, setOpenTradingTable]                       = useState(false);


    
    const [homeFixture, setHomeFixture]   = useState(allTrades?.products ?? []);


    const history = useHistory();
    const systemColorManager = systemColor()


   const dispatch = useDispatch();



    const gameBoxes = [
            {id: 1, title: 'Big',           earn: 1.9},
            {id: 2, title: 'Small',         earn: 1.9},
            {id: 3, title: 'Odd',           earn: 1.9},
            {id: 4, title: 'Even',          earn: 1.9},
            {id: 5, title: 'Big-Odd',       earn: 3.9},
            {id: 6, title: 'Big-Even',      earn: 3.9},
            {id: 7, title: 'Small-Odd',     earn: 3.9},
            {id: 8, title: 'Small-Even',    earn: 3.9},
        ]




    const loadGamesOfUser = (callback = () => {}) => {
        dispatch(tradeActions.onGetTradesProducts(`trade/block/games/mine/${block}`, response => {
console.log('response?.data?.user_games', response)
            if(response?.data?.user_games){
                 setMyPlayedGames(response.data.user_games)
            }

        }))
    }



    const showPlayedGame = () => {
        setOpenMyPlayedGame(true)
    }

    const eachBox = (item) => {
        return <div onClick={() => setSelectedBoxId(item)} style={{paddingTop: 10, paddingBottom: 10, width: '23%', background: item.id == selectedBoxId?'rgba(209, 209, 209, 0.2)':'rgba(0, 0, 0, 0.2)'}} className="mb-4 text-center cursor-pointer">
            {`${item.boxname}`}<br />
            x {`${item.boxpercent}`}
        </div>
    }





    const loadGamesForBlock = (callback = () => {}, loadIminentDate = false) => {
        dispatch(tradeActions.onGetTradesProducts(`trade/allblock/${block}`, response => {
            
            setloading(false)
            setTheGames(response.data)
            setDataChanged(dataChanged + 1)
            callback()

        }))
    }


    const rearrangeGames = () => {
        setTimeIsZero(!timeIsZero)
        downloadTheGameDataAgain()
    }


    const downloadTheGameDataAgain = () => {
        setRefreshingGameData(true)
        loadGamesForBlock(() => {setRefreshingGameData(false)}, false)
    }


    const getFromImminentDate = (theDataToUse = null) => { //DESC from the closest next bet
        
        
        // return [activeGame?.[0], lastOne?.[0]]

    }



    useEffect(() => {
        setloading(true)
        loadGamesForBlock(() => {}, true)
        loadGamesOfUser()

    }, [])



    useEffect(() => {
        const intervalId = setInterval(() => {
          const newMinute = new Date().getMinutes();
          if (newMinute !== minute) {

            // ######################### temporary or transcient
                // if((timeBreaker + 1) < approvedTimebreaker){
                //     setTimeBreaker(timeBreaker + 1)
                // }else{
                    console.log('playableGameAndNext?.[0]?.timeframe 2222', playableGameAndNext)
                    // if((new Date(playableGameAndNext?.[0]?.timeframe) - Date.now()) <= 60000){
                    // if(((new Date(playableGameAndNext?.[0]?.timeframe).getTime()) - new Date().getTime()) <= 3){

                        downloadTheGameDataAgain()
                        loadGamesOfUser()
                        setTimeBreaker(0)
                    // }
                // }
            // ###########################
            
            setMinute(newMinute);
          }
        }, 900);

        return () => clearInterval(intervalId);
    }, [minute]);


    useEffect(() => {

        let baseData = theGames

        if((baseData?.length ?? 0) > 0){ 
            const now = new Date().getTime();

            const activeGame = baseData?.filter(obj => {
              const timeframe = new Date(obj.gameabletime_high);
              const gameabletime_low = new Date(obj.gameabletime_low);
              return (now >= gameabletime_low.getTime() && now <= timeframe.getTime());
            });

            let lastOne = baseData?.filter(obj => (activeGame?.[0]?.txn_id - 1) == obj.txn_id)

            setPlayableGameAndNext((old) => [activeGame?.[0]])

        }

    }, [dataChanged, minute])

    let secondNo = theGames?.filter(obj => (playableGameAndNext?.[0]?.txn_id - 1) == obj.txn_id)

    // useEffect(() => {

    //     const intervalId = setInterval(() => {
    //       getFromImminentDate()
    //     }, 1000);

    //     return () => clearInterval(intervalId);

    // }, []);



    // useEffect(() => {

    //     getFromImminentDate()

    // }, [theGames])



    const submitBet = (e) => {
        e.preventDefault()

        let timingId = playableGameAndNext?.[0]?.id // which timing no. the person played?
        let box = selectedBoxId?.id //big or small or even...

        setloading(true)
        setLoadingText('Bet in progress')

        dispatch(tradeActions.onPOSTTheTrades({timingId, amount, box}, `trade/newblocktrade/${block}`, response => {
            
            notify(response.msg, response.code);
            setloading(false)

            if(response.code == 1){
               setSelectedBoxId(null)
               setMyPlayedGames(response.data.user_games)
            }
            
        }))


    }


    const firstTwoChars = secondNo?.[0]?.hash?.slice(0, 2) ?? '';
    const lastFourChars = secondNo?.[0]?.hash?.slice(-4) ?? '';
    const lastHash = `${firstTwoChars}...${lastFourChars}`




    function Template({ currentItems }){

        return (
            <table style={{maxWidth: '100%', borderCollapse: 'collapse'}} className="table able-responsive">
                <thead>
                    <tr>

                      {openBlockData && <th style={{ border: 'none', textAlign: 'left'}}>Id</th>}

                      <th style={{ border: 'none', textAlign: 'center' }}>Time</th>
                      <th style={{ border: 'none', textAlign: 'center' }}>Result</th>
                      <th style={{ border: 'none', textAlign: 'center' }}>Showcase</th>


                      {openMyPlayedGame && <>
                            <th style={{ border: 'none', textAlign: 'left'}}>Bet</th>
                            <th style={{ border: 'none', textAlign: 'center' }}>Reward</th>
                        </>}

                    </tr>
                </thead>
                <tbody>

                {currentItems && currentItems?.map((item, index) => {

                    const timeString = new Date(item?.timeframe)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });


                    const firstTwoChars = item?.hash?.slice(0, 2) ?? '';
                    const lastFourChars = item?.hash?.slice(-4) ?? '';
                    const lastHash = `${firstTwoChars}...${lastFourChars}`




                    let universalResult = boxers(item, site_settings?.boxes_settings)
                    // const result = item.resultbox ?? item.resultBoxId

                    let namedResult = "";
                    let useles = universalResult?.boxshow?.split(',').forEach((eachNumber) => {
                        namedResult += `<div style="display: 'inline-block'; padding: 2; background-color: ${site_settings?.boxes_settings?.[eachNumber - 1]?.['boxcolor']}">${site_settings?.boxes_settings?.[eachNumber - 1]?.['boxname']}</div> `
                    })

                    return <tr key={index}>
                        {openBlockData && <td className="g-danger" style={{border: 'none', textAlign: 'left'}}>{item.txn_id}</td>}
                        <td className="g-info" style={{ border: 'none', textAlign: 'center' }}>{timeString}</td>
                        <td style={{ border: 'none', textAlign: 'center' }}>{lastHash}</td>
                        <td className="g-info" style={{ border: 'none', textAlign: 'center' }}>{parse(namedResult)}</td>

                        {openMyPlayedGame && <>
                            <td className="g-danger" style={{border: 'none', textAlign: 'center'}}>
                                <div style={{display: 'inline-block', width: '100%', padding: 2, backgroundColor: site_settings?.boxes_settings?.[item.boxId - 1]?.['boxcolor']}}>
                                    <span style={{color: '#000'}}>${item.capital}</span>
                                </div>
                            </td>
                            <td className="g-info" style={{ border: 'none', textAlign: 'center' }}>{universalResult?.boxshow?`$${(universalResult?.profit).toFixed(2)}`:''}</td>
                        </>}
                      </tr>
                })}

                </tbody>
            </table>)

        
    }

    const theGamesFromLast = theGames?.filter((item) => item.hash != 'XXXXXXXXXX');


    // const openTradingCenter = () => {
    //     setOpenTradingTable((ie) => !ie)
    // }


    return (

        <SiteWide removeFooter={true} textColor={{color: '#fff'}} background={{background: "#3c0f8b"}} loading={loading} overlayText={loadingText} title={`${block} Min. Game`}>
            

            <div onClick={showPlayedGame} style={{position: 'absolute', top: 85, right: 10}}>
                <div className="cursor-pointer" style={{marginTop: 30, paddingBottom: 30, paddingLeft: 20, display: 'flex', justifyContent: 'flex-end'}}>

                   My Games

                </div>
            </div>


            <Images style={{borderRadius: 10, marginBottom: 10}} source={site_settings.image_settings?.[0]?.[`image${imageThings}`]} alt="Referral Commission" />


            <div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>

                <div style={{flex: 1, color: '#fff', fontSize: 20}}>No. {playableGameAndNext?.[0]?.txn_id}</div>

                {playableGameAndNext?.[0]?.timeframe && <div className="" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <AiFillClockCircle size={"16"} color="#fff" />
                    <div style={{marginLeft: 4}} className="text-danger">
                        <Countdown
                            key={new Date(playableGameAndNext?.[0]?.timeframe)}
                            onComplete={() => {}}
                            date={new Date(playableGameAndNext?.[0]?.timeframe)}
                            renderer={({ hours, minutes, seconds, completed }) => {
                              if (completed) {
                                return <span></span>
                              } else {
                                return <span style={{fontSize: 16}} className="text-white">{hours}:{minutes}:{seconds}</span>;
                              }
                            }}
                        />
                    </div>
                </div>}

            </div>

            <div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>

                <div style={{flex: 1, color: '#fff', fontSize: 20}}>No. {secondNo?.[0]?.txn_id}</div>

                <div onClick={() => setOpenBlockData(true)} className="cursor-pointer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <FcApproval size={"16"} color="#fff" />

                    {!refreshingGameData && <div style={{marginRight: 4, fontSize: 16, fontWeight: 900}} className="text-white"><i>{lastHash}</i></div>}
                    {refreshingGameData && <BarLoader color="#fff" />}

                    <AiOutlineDownSquare size={"16"} color="#fff" />
                </div>

            </div>

            <p style={{marginTop: 20}}>
                {parse(site_settings?.content_settings?.[0]?.[`con${arrangeBlock}`] ?? '')}
            </p>
            
            <div className="" style={{display: 'flex', justifyContent: 'space-between', marginTop: 30}}>

                {site_settings?.boxes_settings?.slice(0, 4).map((item) => eachBox(item))}

            </div>

            <div className="" style={{display: 'flex', justifyContent: 'space-between', marginTop: 0}}>

                {site_settings?.boxes_settings?.slice(4, 8).map((item) => eachBox(item))}

            </div>


            
            


            <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={selectedBoxId} onClose={() => setSelectedBoxId(null)}>
                
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className="" style={{maxHeight: '70vh', width: '90%', overflowY: "auto" }}>






                        <form onSubmit = {submitBet} action="" style={{}}>
                    
                            <div class="single-input-wrap">
                                
                                <div className="flexify"> 
                                    <span className="new-input-label" style={{flex: 1, ...systemColorManager.txt_1}}>Account Balance: {money(finance?.[0]?.balance, finance?.[0]?.type)}</span>
                                    <WebLink to={"/block/games/plan"} className="cursor-pointer" style={{...systemColorManager.txt_1}}>Trading Plan</WebLink>
                                </div>
                                <div className="input-group">
                                    <input type="text" className="form-field form-control" placeholder="Sum of bet placement" onChange = {(e) => setAmount(e.target.value)} value={amount} style={{...systemColorManager.borders_color, ...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
                                </div>

                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 10}}>

                                <div style={{backgroundColor: '#d1f6ca', color: '#000', border: '0.5px solid green', marginRight: 5, flex: 1, padding: 7}}>
                                    <div style={{fontSize: 10}}>Bet for No. {playableGameAndNext?.[0]?.txn_id}</div>
                                    {selectedBoxId?.boxname} x{selectedBoxId?.boxpercent}
                                </div>

                                <div style={{backgroundColor: '#fedec1', color: '#000', border: '0.5px solid #f70', flex: 1, padding: 7}}>
                                    <div style={{fontSize: 10}}>Possible win</div>{(amount * selectedBoxId?.boxpercent).toFixed(2)}
                                </div>

                            </div>

                            <div class="sngle-input-wrap" style={{marginBottom: 10}}>

                                <button type="submit" disabled={loading} className="btn btn-block text-white" style={{...systemColorManager.btn_3}}>
                                Place Bet </button>
                            
                            </div>

                        </form>
                       

                    </div>
                </div>

            </DownModal>


            <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={openBlockData} onClose={() => setOpenBlockData(false)}>

                <div style={{maxHeight: '70vh', padding: 10}}>

                    {(theGamesFromLast?.length ?? 0) > 0 && <PaginatedItems currentPageCallBack={() => {}} Template={Template} arrayOfDataToDisplay={theGamesFromLast} itemsPerPage={50} />}

                </div>

            </DownModal>






            <DownModal containerStyle={{...systemColorManager.bg_1}} isOpen={openMyPlayedGame} onClose={() => setOpenMyPlayedGame(false)}>

                <div style={{maxHeight: '70vh', padding: 10}}>

                    {(myPlayedGames?.length ?? 0) > 0 && <PaginatedItems currentPageCallBack={() => {}} Template={Template} arrayOfDataToDisplay={myPlayedGames} itemsPerPage={50} />}

                </div>

            </DownModal>



        </SiteWide>
    );
}

export default BlockGames;


