import * as actionTypes from "./actionTypes";

export const onPOSTTheTrades = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.TRADE_POST,
    callback,
  };
};


export const onGetTradesProducts = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.TRADE_GET_PRODUCTS,
    callback,
  };
};

export const onGetUserTrades = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.TRADE_GET_USER_PURCHASES,
    callback,
  };
};


export const onDummy = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.TRADE_DUMMY,
    callback,
  };
};

export const onDummyPost = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.TRADE_DUMMY_POST,
    callback,
  };
};

