import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import Ticker1 from "./customComponents/Ticker1";
import Ticker2 from "./customComponents/Ticker2";

import {systemColor} from '../utility/styles';

import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import Colors from '../utility/Colors';
import {homepage, contact_us} from '../utility/APIRequests';
import MobileBreaker from "./layouts/MobileBreaker";
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, footballSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {footballActions, authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import Helper1, {byNextDate, niceDate, formatTime, formatDate, displayTime, notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiCheckboxIndeterminateLine, RiMenuLine, RiCheckboxLine } from "react-icons/ri";
import { AiFillSliders, AiOutlineFieldTime, AiOutlineComment, AiOutlineMail } from "react-icons/ai";
import { FaStar, FaChevronRight, FaSun, FaCloudMoon } from "react-icons/fa";
import Cookies from 'js-cookie'

import {FcBookmark, FcBriefcase, FcBrokenLink, FcUpload, FcBusinessContact, FcCableRelease, FcDiploma2} from "react-icons/fc";
// import Ticker from 'react-ticker'
import Marquee from 'react-css-marquee'

import EachFixture from "./Football/EachFixture"

import BetModalPlace from './Football/BetModalPlace';
import parse from 'html-react-parser';


import { AiFillAlert, AiFillWechat, AiFillApi, AiFillAppstore, AiFillBook, AiFillBuild, AiFillBulb } from "react-icons/ai";

// import { HiOutlineChartPie, HiOutlineCalculator, HiOutlineCalendarDays, HiOutlineCalendar, HiOutlineCamera, HiOutlineChartBarSquare, HiOutlineChartBar } from "react-icons/hi2";

import CustomAgo from "./customComponents/CustomAgo";

import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'

import NewsTicker from "./customComponents/Ticker/NewsTicker";
import { PuffLoader } from 'react-spinners';
import SocialMediaIcons from './SocialMediaIcons';


const nextMatch = {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}




function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const darkVal = (Cookies.get('darkMode') ?? 1)

    const [isDarkMode, setisDarkMode] = useState(darkVal) //dark mode by default

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData    = useSelector(allTradeSelect);
    const footballData      = useSelector(footballSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState('');

    const [recentBets, setRecentBets]   = useState([]);
    const [recentBetsLoading, setRecentBetsLoading]   = useState(true);


    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [homeFixture, setHomeFixture]   = useState(byNextDate(footballData?.fixtures));

    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref2 = useRef();
    const balanceref1 = useRef();
    const chooseCurrency1 = useRef();
    const chooseCurrency2 = useRef();

    const systemColorManager = systemColor()
    const [betModal, setBetModal]          = React.useState(false);
    const [selectedOddObj, setSelectedOddObj]          = React.useState({});


    const dispatch = useDispatch();

    const ref = useRef();


    useEffect(() => {
        setHomeFixture(byNextDate(footballData?.fixtures));
    }, [footballData?.fixtures])


    useEffect(() => {
        loadRecentBets()
    }, [])

    const loadRecentBets = () => {

        if((site_settings?.football_settings?.show_recent_bets_on_homepage ?? 0) == 0){ return; }

        setRecentBetsLoading(true)
        dispatch(footballActions.onGetRecents("football/home-recent", response => {

            setRecentBetsLoading(false)

        }))

    }


    const changeToDarkMode = (booleanVal) => {
        Cookies.set('darkMode', booleanVal, { secure: true, sameSite: 'Lax', expires: 7})
        setisDarkMode(booleanVal)
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 2000)
        
        return () => clearTimeout(timing);
    }, [])



    
    



    // main slider
    const settings = {
        fade: true,
        dots: false,
        infinite: true,
        speed: 1000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };



    const eachNewsClicked = (item) => {
        history.push(`/news?id=${item.id}`)
    }




    const eachIsClicked = (eachFixture) => {

        if(!userData?.accountId){
            history.push(`signin`)
            return;
        }
        setSelectedOddObj(eachFixture)
        setBetModal(true)
    }



    const balancePlace = require('../images/balance-block.webp')




    const palettes = [
    {
      from: "#f40076",
      to: "#df98fa"
    },
    {
      from: "#f06966",
      to: "#fad6a6"
    },
    {
      from: "#ff0076",
      to: "#590fb7"
    },
    {
      from: "#9055ff",
      to: "#13e2da"
    },
    {
      from: "#0b63f6",
      to: "#003cc5"
    },
    {
      from: "#d6ff7f",
      to: "#00b3cc"
    },
    {
      from: "#e233ff",
      to: "#ff6b00"
    },
    {
      from: "#df98fa",
      to: "#9055ff"
    },
    {
      from: "#ed7b84",
      to: "#9055ff"
    },
    {
      from: "#402565",
      to: "#30be96"
    },
    {
      from: "#402662",
      to: "#3900a6"
    },
    {
      from: "#f14658",
      to: "#dc2537"
    },
    {
      from: "#f40076",
      to: "#342711"
    },
    {
      from: "#000066",
      to: "#6699ff"
    },
    {
      from: "#cb5eee",
      to: "#4be1ec"
    },
    {
      from: "#fa7cbb",
      to: "#f14658"
    },
    {
      from: "#737dfe",
      to: "#ffcac9"
    },
    {
      from: "#2f80ed",
      to: "#b2ffda"
    }
  ];




    const gameMenu = [
            {title1: '1 Minute', title2: 'Game', to: '/games/1'},
            {title1: '3 Minute', title2: 'Game', to: '/games/3'},
            {title1: '5 Minute', title2: 'Game', to: '/games/5'},
            {title1: 'Ecommerce', title2: 'Shop', to: '/ecommerce'},
        ]
    return (

        <SiteWide overlayText={loadingText} loading={loading}>                


                <div className="row no-gutter" style={{margin: 0}}>

                    <div className="col-12">
                        <Slider className="main-home-slider" arrows={false} {...settings}>
                            {site_settings?.slider_settings?.map((item, index) => (
                                <div style={{}} className="">
                                    <a target="_blank" href={item?.linkWhenClicked}>
                                        <Images style={{}} resizeMode="contain" height="200" width="100%" source={item?.image} />
                                    </a>
                                </div>)
                            )}
                        </Slider>
                    </div>
                    

                </div>



                <div className="" style={{display: 'flex', flexDirection: 'row', backgroundImage: `url(${balancePlace})`, background: `linear-gradient(45deg, ${palettes[10].to}, ${palettes[10].from})`, backgroundPosition: '100px 100px', borderRadius: 5, padding: 10, paddingBottom: 20, paddingTop: 20, marginBottom: 20}}>
                    

                    <div>
                        <Images style={{marginRight: 5, width: 50, display: 'inline'}} source={require(`../images/bal-place.png`)} />
                    </div>

                    <div>
                        <span className="text-white">Wallet Balance</span>
                        <h3 className="prem text-white">
                            {finance?.[0]?.type?money(finance?.[0]?.balance, finance?.[0]?.type) : '0.00'}
                        </h3>
                    </div>


                </div>








                <div className="row no-gutter">


                    {gameMenu.map((item, index) => <WebLink to={item.to} className="col-6">
                        <div style={{backgroundImage: `linear-gradient(45deg, ${palettes[10].to}, ${palettes[10].from})`, padding: 0, backgroundPosition: '100px 100px', borderRadius: 5, marginBottomf: 20}}>
                            
                            <div className="text-center" style={{backgroundImage: `linear-gradient(45deg, #4876ec, ${palettes[10].to})`, paddingBottom: 20, paddingTop: 20,}}>
                                
                                <Images style={{marginBottom: 5, width: 40, display: 'inline'}} source={require(`../images/sq-${index}.png`)} />

                                <div className="text-white">{item.title1}</div>

                                <h5 className="prem text-white">
                                    {item.title2}
                                </h5>
                            </div>

                        </div>
                    </WebLink>)}


                </div>































                    <div style={{marginTop: 28, marginBottom: 28}}>

                        <div className="" style={{borderRadius: 10, backgroundImage: `linear-gradient(45deg, ${palettes[10].to}, ${palettes[10].from})`}}>

                            {/*<div className="text-center" style={{padding: 20}}>
                                <WebLink to={'/'} className="logo text-center">
                                    <Images height="40px" src={(site_settings?.site_settings?.logo)} alt="logo" />
                                </WebLink>
                            </div>*/}



                            <div  style={{ padding: 20}}>
                                <p className="text-whit" style={{color: '#f7f8fa'}}>
                                    {parse(site_settings?.content_settings?.[0]?.con3 ?? '')}
                                </p>

                                <div className="text-center" style={{marginTop: 25}}>
                                    <button onClick={() => history.push('/about')} className="bt" style={{paddingRight: 14, paddingLeft: 14, borderRadius: 12, backgroundColor: '#6f9ac3', border: 'none', color: '#fff'}}>More <i class="fa fa-long-arrow-right"></i></button>
                                </div>
                            </div>


                           


                        </div>



                    </div>
                      

                     <SocialMediaIcons/>

                    {(site_settings?.site_settings?.showAccreditation ?? 0) == 1 && <div className="flexify" style={{marginTop: 30, padding: 5, paddingBottom: 20, ...systemColorManager.txt_3}}>
                        <div className="text-center" style={{flex: 1}}>Copyright &copy; {(new Date).getFullYear()} - All right reserved</div>
                    </div>}



        </SiteWide>
    );
}

export default Home;
