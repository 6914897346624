import React, {Component, useState, useEffect} from "react";

import Navbar from "../../layouts/Navbar";
import {WebLink, SiteWide} from "../../customComponents";
import NoData from "../../customComponents/NoData";

import Section from "../../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../../customComponents";
import Colors from '../../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../../utility/Helper1';

import {tradeActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, allTradeSelect, homeScreenStatSelect } from '../../../redux/selectors/index';
import CustomAgo from "../../customComponents/CustomAgo";

import {systemColor} from '../../../utility/styles';
import { AiFillClockCircle, AiOutlineDownSquare } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";

import PaginatedItems from "../../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";

import DownModal from "../../layouts/DownModal";

import parse from 'html-react-parser';
import Countdown from 'react-countdown';
import BarLoader from 'react-spinners/BarLoader'








function MyGameList() {

    const params = useParams();
    const block = params.block
    const arrangeBlock = (block == 1)?8:(block == 3)?9:10


    const userData                          = useSelector(userSelect);
    const allTrades                          = useSelector(allTradeSelect)

    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [loadingText, setLoadingText]     = useState('Loading Bets');

    const [gettingProducts, setGettingProducts]             = useState(false);
    const [loading, setloading]                             = useState(false);

    const [amount, setAmount]                             = useState('');
    const [selectedBoxId, setSelectedBoxId]               = useState(null);

    

    const [getCurrentPage, setGetCurrentPage]             = useState(1);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    const [openBlockData, setOpenBlockData]             = useState(false);
    const [theGames, setTheGames]                       = useState([]);
    const [refreshingGameData, setRefreshingGameData]                       = useState(false);
    const [errorMessage, setErrorMessage]                       = useState('');

    // const [playableGameAndNext, setPlayableGameAndNext]                       = useState([]);


    


    const history = useHistory();
    const systemColorManager = systemColor()


   const dispatch = useDispatch();



    let minimumTrading = site_settings?.boxes_settings?.[0]?.minimumTrading;


   const divideByTwo = () => {

        let allAmountArrays = []


        if((amount == '') || (amount == 0)){ return; }

        let result = amount;
        let totalStages = site_settings?.boxes_settings?.[0]?.totalTradingTableStage

        let summation = 0;

        for (let i = 1; i <= totalStages; i++) {
           
            result = (result / 2).toFixed(2);
            
            allAmountArrays = [...allAmountArrays, result]

        }

        // Sum up the numbers from the second array onwards
        const sumOther = allAmountArrays.slice(1).reduce((acc, val) => Number(acc) + Number(val), 0);

        // Replace the first element in the array with the sum
        allAmountArrays[0] = (amount - sumOther).toFixed(2);

        return allAmountArrays;
    }

    const playableGameAndNext = divideByTwo()



    const theGamesFromLast = theGames;

    return (

        <SiteWide removeFooter={true} textColor={{color: '#fff'}} ackground={{background: "#3c0f8b"}} loading={loading} overlayText={loadingText} title={`Trading Plan`}>
            

            <Images style={{borderRadius: 10, marginBottom: 10}} source={site_settings.image_settings?.[0]?.[`image9`]} alt="my games" />

        

            <div className="input-group" style={{marginBottom: 10}}>
                <input type="text" className="form-field form-control" placeholder="Enter Trading Capital" onChange = {(e) => setAmount(e.target.value)} value={amount} style={{...systemColorManager.borders_color, ...systemColorManager.bg_3, ...systemColorManager.txt_1}} />
            </div>



            {Number(amount) < Number(minimumTrading) && amount !='' && <div className="text-center text-dark h3">
                Minimum trading plan is ${minimumTrading} 
            </div>}

            {playableGameAndNext?.length > 0 && Number(amount) >= Number(minimumTrading) &&  <table style={{maxWidth: '100%', borderCollapse: 'collapse'}} className="table able-responsive">
                <thead class="bg-warning">
                    <tr>


                      <th style={{color: '#000', border: 'none', textAlign: 'center' }}>Stage</th>
                      <th style={{color: '#000', border: 'none', textAlign: 'center' }}>Trade</th>
                      {1>2 && <><th style={{color: '#000', border: 'none', textAlign: 'center' }}>Profit</th>
                      <th style={{color: '#000', border: 'none', textAlign: 'center' }}>Return</th></>}


                    </tr>
                </thead>
                <tbody class="bg-info">

                {playableGameAndNext?.slice()?.reverse()?.map((item, index) => {

                    let theReturn = (item*site_settings?.boxes_settings?.[0]?.profitOddUsedToCalculateTradingTable).toFixed(2)
                    let profit = (theReturn-item).toFixed(2)

                    return <tr key={index}>
                        <td className="g-info" style={{color: '#000', border: 'none', textAlign: 'center' }}>{index+1}</td>
                        <td className="g-info" style={{color: '#000', border: 'none', textAlign: 'center' }}>${item}</td>
                        {1>2 && <><td className="g-info" style={{color: '#000', border: 'none', textAlign: 'center' }}>${profit}</td>
                        <td className="g-info" style={{color: '#000', border: 'none', textAlign: 'center' }}>${theReturn}</td></>}
                    </tr>
                })}

                </tbody>
            </table>}


        </SiteWide>
    );
}

export default MyGameList;


