import React, {useState, useEffect} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';


import Countdown from 'react-countdown';

import copy from 'copy-to-clipboard';
import {staticCountries} from '../../utility/countries';
import {Images} from "../customComponents";
import Slider from "react-slick";
import { RiFileCopyLine, RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import QRCode from "react-qr-code";
import {systemColor} from '../../utility/styles';
import parse from 'html-react-parser';
import DownModal from "../layouts/DownModal";


function RefLink({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const systemColorManager = systemColor()
    
    const [selectedLevel, setSelectedLevel]   = useState(1);
    const [invitationCodeModal, setInvitationCodeModal]   = useState(false);

    const dispatch = useDispatch();

    const copyRefLink = () => {
        notify('Referral Link Copied', 1)
    }


    const myRefCode = userData?.myrefid
    const myRefLink = site_settings?.site_settings?.site_url+'/#/signup?ref='+ myRefCode

    
    const toggleInviteModal = () => {
        setInvitationCodeModal(!invitationCodeModal)
    }


    const copyThis = (itemToCopy) => {
        copy(itemToCopy)
        notify('Referrer link is copied', 1)
    }

    return (
        <SiteWide title={`Copy Referrer Link`}>
            

            <div style={{position: 'absolute', top: 85, right: 10}}>
                {site_settings?.referral_settings?.length > 1 && <div className="" style={{marginTop: 30, paddingBottom: 30, paddingLeft: 20, display: 'flex', justifyContent: 'flex-end'}}>

                    {site_settings?.referral_settings?.map((each, index) => {

                        let item = index + 1
                        let isActiveHere = selectedLevel == item?systemColorManager.btn_3:systemColorManager.btn_2

                        return <>
                            <button onClick={() => setSelectedLevel(item)} style={{border: 'none', ...isActiveHere, ...systemColorManager.txt_1, marginRight: 5, borderRadius: 20, width: 20, height: 20, color: '#fff'}} className={`flexify btn-sm`}>{item}</button>
                        </>})}

                </div>}
            </div>


            
            <Images style={{borderRadius: 10}} source={site_settings.image_settings?.[0]?.image4} alt="Referral Commission" />


                <div style={{marginTop: 20, ...systemColorManager.txt_1, color: '#0d469f'}}>
                    {parse(site_settings?.referral_settings?.[selectedLevel-1]?.[`note_for_level_${selectedLevel}`])}
                </div>

                
                <div style={{...systemColorManager.txt_1, marginTop: 20, marginBottom: 20}}>

                    <button style={{...systemColorManager.btn_1, ...systemColorManager.txt_1}} className="btn btn-info btn-block cursor-pointer flexify" onClick={() => copyThis(myRefLink)}>COPY LINK</button>
                    
                </div>






        </SiteWide>
    );

}

export default RefLink;