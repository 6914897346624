import React, {Component, useState, useEffect} from "react";

import Navbar from "../../layouts/Navbar";
import {WebLink, SiteWide} from "../../customComponents";
import NoData from "../../customComponents/NoData";

import Section from "../../layouts/Section";
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {Images} from "../../customComponents";
import Colors from '../../../utility/Colors';
import Helper1, {byNextDate, notify, styles, money, formatTime, formatDate} from '../../../utility/Helper1';

import {tradeActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { allDepositSelect, userSelect, allRemoteSettingsSelect, allTradeSelect, homeScreenStatSelect } from '../../../redux/selectors/index';
import CustomAgo from "../../customComponents/CustomAgo";

import {systemColor} from '../../../utility/styles';
import { AiOutlineClockCircle } from "react-icons/ai";

import PaginatedItems from "../../customComponents/pagination/PaginatedItems";
import Slider from "react-slick";

import DownModal from "../../layouts/DownModal";

import "./styles/responsive.css";
import "./styles/style.css";
import parse from 'html-react-parser';




const nextMatch = {
    slidesToShow: 2.5,
    slidesToScroll: 2,
    dots: false,
    infinite: false,
}



function AllFixtures() {

    const userData                          = useSelector(userSelect);
    const allTrades                          = useSelector(allTradeSelect)

    const site_settings                     = useSelector(allRemoteSettingsSelect);
    const [loadingText, setLoadingText]     = useState('Loading products');

    const [gettingProducts, setGettingProducts]             = useState(true);
    const [loading, setloading]                          = useState(true);

    const [getCurrentPage, setGetCurrentPage]             = useState(1);
    const [selectedOddObj, setSelectedOddObj]             = useState();
    
    const [homeFixture, setHomeFixture]   = useState(allTrades?.products ?? []);


    const history = useHistory();
    const systemColorManager = systemColor()


   const dispatch = useDispatch();


   useEffect(() => {

        dispatch(tradeActions.onGetTradesProducts("trade/images/load", response => {
         setGettingProducts(false)
         setloading(false)

        }))

    }, [])


   useEffect(() => {

       setHomeFixture(allTrades?.products ?? []);

    }, [allTrades])


    const purchaseProduct = (item) => {

        dispatch(tradeActions.onPOSTTheTrades({id: item.id}, "trade/new", response => {
            
            notify(response.msg, response.code);

            if(response.code == 1){
               history.push('/ecommerce/mine')
            }
            
        }))

    }


    function Template({ currentItems }){

        return (
            <>
                {currentItems && currentItems.map((item) => <div className="col-12">
                    <div className="product_box" style={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                       
                       <h4 className="bursh_text">{item.name}</h4>


                       <div className="prem" style={{backgroundColor: '#4876ec', color: '#fff', width: 50, height: 50, position: 'absolute',
                        justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: 50, right: 30, top: 120}}>{item.percentage}%</div>

                       <p className="lorem_text">{item.description}</p>
                       
                       <div className="text-center">
                        <Images source={item.processedimage} style={{height: 'auto', maxWidth: '240px'}} className="image_1"/>
                       </div>

                       <div className="btn_main">
                           <div style={{flex: 1}}>
                              
                              <span classname="h3 price_text">{item.amount} USDT</span><br />
                              
                              <div className="" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                 <AiOutlineClockCircle color="#fff" />
                                 &nbsp;<small>{item.hoursToCashBack}hrs</small>
                              </div>

                           </div>
                           <a className="cursor-pointer" onClick={() => window.confirm(`Are you sure you want to purchase ${item.name}? ${item.amount} USDT will be deducted from your wallet balance`) && purchaseProduct(item)} style={{paddingRight: 14, paddingLeft: 14, borderRadius: 12, backgroundColor: '#6f9ac3', border: 'none', color: '#fff'}}>Buy Now</a>
                       </div>

                    </div>

                 </div>)}
            </>)
        
    }


    const getCurrenPageTaawa = (thePage) => {
      setGetCurrentPage(thePage)
    }



    return (

        <SiteWide loading={loading} overlayText={loadingText} title={`Ecommerce`}>
            

            <div style={{position: 'absolute', top: 85, right: 10}}>
               <div className="text-dark" style={{marginTop: 30, paddingBottom: 30, paddingLeft: 20, display: 'flex', justifyContent: 'flex-end'}}>

                  Page {getCurrentPage}

                </div>
            </div>


            <div className="">
               
               <div className="row">
                 <div className="col-sm-12">
                    <h1 className="product_taital">{parse(site_settings?.content_settings?.[0]?.con4 ?? '')}</h1>
                    <p className="product_text">{parse(site_settings?.content_settings?.[0]?.con5 ?? '')}</p>
                 </div>
               </div>

               {!gettingProducts && <div className="row">
                  
                  {(homeFixture?.length ?? 0) > 0 && <PaginatedItems currentPageCallBack={getCurrenPageTaawa} Template={Template} arrayOfDataToDisplay={homeFixture} itemsPerPage={10} />}

               </div>}




            </div>



        </SiteWide>
    );
}

export default AllFixtures;