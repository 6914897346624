import React, {Component, useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {systemColor} from '../utility/styles';

import {Images} from "./customComponents";
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import PaginatedItems from "./customComponents/pagination/PaginatedItems";
import FullScreenInputHolder from "./customComponents/FullScreenInputHolder";

    

function News(){

    const site_settings = useSelector(allRemoteSettingsSelect);
    const [lightImages, setLightImages]   = useState([]);
    const [openLightBox, setOpenLightBox]   = useState(false);
    const systemColorManager = systemColor()


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const newsId = searchParams.get('id') ?? null;


    const newsEnjin = (item) => {
        return <div style={{padding: 20, marginBottom: 30}}>

            <div>
                <h5 style={{...systemColorManager.txt_1}}>{item?.subject}</h5>
            </div>

            <hr />
            <p style={{...systemColorManager.txt_1}}>{parse(item?.message ?? '')}</p>

        </div>
    }


    function Template({ currentItems }){

        return newsEnjin(currentItems?.[0])

    }


    return (
        <SiteWide id="main" title={"News"}>

            <FullScreenInputHolder>
                {(searchParams.has('id')) && <div>

                    {site_settings?.news?.map((item, index) => {

                        if((item.id != newsId)){ return <></>}

                        return newsEnjin(item)})}
                        
                    
                </div>}


                {!(searchParams.has('id')) &&  <PaginatedItems Template={Template} arrayOfDataToDisplay={site_settings?.news} itemsPerPage={1} />}

            </FullScreenInputHolder>

        </SiteWide>
    );

}

export default News;